<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-btn flat label="back" color="primary" @click="$router.go(-1)"></q-btn>
      <q-separator></q-separator>
      <q-card-section class="q-px-md bg-grey-1">
        <q-markup-table flat wrap-cells class="bg-grey-1">
          <tr>
            <td>Nama</td>
            <td>:</td>
            <td>{{ dataMusyrif.nama }}</td>
          </tr>
          <tr>
            <td>Kontak</td>
            <td>:</td>
            <td>{{ dataMusyrif.hp }}</td>
          </tr>
        </q-markup-table>
      </q-card-section>
      <q-separator></q-separator>
      <!-- <q-card-section>
        <q-input outlined label="Cari Siswa" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>-->
      <q-markup-table
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
        style="height: calc(100vh - 232px)"
      >
        <thead>
          <tr>
            <th>No.</th>
            <th>Nama ({{ filSiswa.length }})</th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(val, i) in filSiswa" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.nama }}</td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataMusyrif: {},
      listAnggota: [],
      searchTerm: ""
    };
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.listAnggota;
      else {
        return this.listAnggota.filter(val => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    }
  },
  async mounted() {
    await this.getDataMusyrif();
    await this.getAnggotaMusyrif();
  },
  methods: {
    async getDataMusyrif() {
      let resp = await this.$http.get(
        `/pengaturan/musyrif/getdetail/${this.$route.params.id}`
      );
      this.dataMusyrif = resp.data;
    },
    async getAnggotaMusyrif() {
      let resp = await this.$http.get(
        `/pengaturan/musyrif/getanggota/${this.$route.params.id}`
      );
      this.listAnggota = resp.data;
    }
  }
};
</script>

<style lang="scss" scoped></style>
